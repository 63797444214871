import React from 'react';
import HomePage from '../components/Views/HomePage';
import { getSeoData } from '../utils/helpers';
import Layout from '../components/Layout/Layout';

const App = () => <HomePage />;

App.getInitialProps = async ({ apolloClient, asPath, req }) => {
  const seoData = await getSeoData(apolloClient, asPath, req);

  return {
    seoData
  };
};

App.getLayout = page => <Layout>{page}</Layout>;

export default App;
